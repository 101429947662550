import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import '../views/home.css'

function BouncingAnimation() {
  const boxRef = useRef(null);

  useEffect(() => {
    const box = boxRef.current;

    gsap.to(box, {
      y: -50,
      duration: 1.5,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    });
  }, []);

  return (
    <div className="home-image5">
      <div ref={boxRef} className="home-imageX"><img
        alt="image"
        src="/external/scroll%20down-1500h.png"
        className="home-image5"
      /></div>
    </div>
  );
}

export default BouncingAnimation;
