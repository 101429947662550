import React,{useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import gsap from 'gsap';
import './contactus.css'

import TitlesX from '../components/titlesX'

import SiteName from '../components/sitename'
import customLogo from '../components/KstudiosLogo.png';
import Behance from '../components/Behance'
import MenuDropOthers from '../components/menuDropOthers';
import BouncingAnimationContact from '../components/BouncingAnimationContact';

const Contactus = (props) => {

  const [MenuOpened, setMenuOpened] = useState(false);

  function MenuChanger() {
    setMenuOpened(!MenuOpened);
  }

  function Close(){
    handleButtonClick();
  }

  const buttonRef = useRef(null);
    const buttonRef2 = useRef(null);
    const [isRotated, setIsRotated] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const handleButtonClick = () => {
        const rotation = isRotated ? 0 : 45;
        const rotationXy = isRotated ? 0 : -45;
        const translateY = isMoved ? 0 : 8;
        const translateY2 = isMoved ? 0 : -8;

        gsap.to(buttonRef.current, {
            rotation: rotation,
            y: translateY,
            duration: 0.5,
        });

        gsap.to(buttonRef2.current, {
            rotation: rotationXy,
            y: translateY2,
            duration: 0.5,
        });

        setIsRotated(!isRotated); // Update state variables
        setIsMoved(!isMoved); // Update state variables
        MenuChanger();
        // console.log(isRotated);
        console.log(MenuOpened);

    };
    useEffect(()=>{
      gsap.fromTo('.home-imgXY', {
          y:-70
      }, {y:0, delay:0.4})
    },[])

    useEffect(() => {
    

      gsap.fromTo('.about-text01', {x:150, opacity:0},{
        x:0,opacity:3, duration:1
      });
  
      gsap.fromTo('.about-image1', {x:120, opacity:0},{
        x:0,opacity:4, duration:1, delay:0.3
      });

        // Contact Us
    
        gsap.fromTo('.contactus-image1', {x:-600},{
          x:0, duration:1
        });
    
        gsap.fromTo('.contactus-container4', {x:+1200},{
          x:0, duration:1
        });

        gsap.fromTo('.contactus-text1', {y: 90, opacity:0},{
          y: 0, duration:1, opacity:1, delay:0.8
        });
        gsap.fromTo('.contactus-text2', {y: 90, opacity:0},{
          y: 0, duration:1, opacity:1, delay:1.2
        });
        gsap.fromTo('.contactus-text3', {y: 90, opacity:0},{
          y: 0, duration:1, opacity:1, delay:1.7  
        });
    }, []);

    const containerRef = useRef(null);

  useEffect(() => {
    const children = containerRef.current.children;

    const timeline = gsap.timeline({ defaults: { duration: 1, ease: 'power2.out' } });

    gsap.from(children, {
      x: -100,
      delay:1.5,
      opacity: 0,
      stagger: 0.2,
      onComplete: () => {
        // Animation is complete
      },
    });
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setLoading(false);
      clearTimeout(timer);
    }, 5000);


  });

  return (
    <div className="contactus-container">
      {(loading)?<div  style={{zIndex:36, background:'black', display:'flex', justifyContent:'center', alignItems:'center', position:'fixed', height:'100vh', width:'100%'}}>
        <img src="/external/2RNb.gif" alt="Loading" />
      </div>:null}
      <Helmet>
        <title>contactus - Kesterstudios</title>
        <meta property="og:title" content="contactus - Kesterstudios" />
        <link
          rel="icon"
          href={customLogo}
          type="image/png"
        />
      </Helmet>
      {/* Zoomable  */}
        <BouncingAnimationContact />
      <div className="contactus-container1">
      <video alt="image" src="/external/tunnel_-_27438 (1080p).mp4" autoplay="" loop class="full-video" muted></video>

      {(MenuOpened)?<MenuDropOthers close={Close} rColor={'contactus'} />:null} 
      <div className="services-container02">
            <span className="about-text01">Contact Us</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image1"
            />
        </div>
        <div className="contactus-container3">
          <img
            alt="image"
            src="/external/contact.gif"
            className="contactus-image1"
          />
          <div className="contactus-container4">
            <span className="contactus-text1">Contact</span>
            <span className="contactus-text2">
              Choose the method you want to use to reach out to us.
            </span>
            <div className="contactus-container5" ref={containerRef}>
              <a href='mailto:kstudio860@gmail.com' target='_blank'><img
                alt="image"
                src="/external/email-200h.png"
                className="contactus-image2"
              /></a>
              <a href='tel:+2348163577508' target='_blank'><img
                alt="image"
                src="/external/phone-200h.png"
                className="contactus-image3"
              /></a>
              <a href='https://join.skype.com/invite/LFig8xYSIjjo' target='_blank'><img
                alt="image"
                src="/external/skype-200h.png"
                className="contactus-image4"
              /></a>
              <a href='https://wa.me/+2348163577508?text=I%20want%20to%20hire%20you' target='_blank'><img
                alt="image"
                src="/external/whatsapp-200h.png"
                className="contactus-image5"
              /></a>
              <a href='https://t.me/Kstudiosx' target='_blank'><img
                alt="image"
                src="/external/telegram-200h.png"
                className="contactus-image6"
              /></a>
            </div>
            <span className="contactus-text3">
              Tell us about your project and we&apos;ll help you handle the rest
            </span>
          </div>
        </div>
      </div>
      <div className="contactus-container6">
        <SiteName />
        
        <TitlesX refObj={'Contact'}/>
        
        <Behance timer={1.3}/>
        <div className='home-imgXY' onClick={handleButtonClick}>
            <img
              alt="image"
              src="/external/upMenu.png"
              className="home-imageXYZ"
              ref={buttonRef}
            />
            <img
              alt="image"
              src="/external/DownMenu.png"
              className="home-imageXYZ"
              ref={buttonRef2}
            />
          </div>
      </div>
    </div>
  )
}

export default Contactus
