import React,{useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import './about.css'
import SiteName from '../components/sitename'
import Titles from '../components/titles'
import Behance from '../components/Behance'
import BouncingAnimation from '../components/BouncingAnimation';
import BouncingAnimationContact from '../components/BouncingAnimationContact';
import './home.css'

const About = (props) => {

  const pinRef = useRef(null);

  useEffect(() => {
    const pinElement = pinRef.current;

    if (!pinElement) return;

    const pinDuration = 2; // Duration of pinning animation in seconds
    const releaseY = 400; // Y-position to release the pinned element
    const screenHeight = window.innerHeight;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: pinElement,
        start: 'top top',
        end: `${screenHeight}px top`,
        pin: true,
        // markers: true, // Add markers to visualize the trigger
        
      },
    });

    // Add animations to the timeline
    tl.from(pinElement, {  duration: pinDuration });

    return () => {
      // Clean up and kill the scrollTrigger on unmount
      tl.scrollTrigger.kill(true);
    };
  }, []);

  // about-text01
  

  return (
    <div className="about-container"  ref={pinRef}>
      
      {/* <Helmet>
        <title>about - Kesterstudios</title>
        <meta property="og:title" content="about - Kesterstudios" />
      </Helmet> */}
      <div className="about-container01" id='targetSection'>
        <div className='aboutInternal'>
        <video
          alt="image"
          src="/external/Promotion Video.mp4"
          autoPlay
          muted
          loop
          className="full-video"
        />
        </div>
      </div>
      
      <div className="about-container02">
      <SiteName timer={0.2}/>
        <Titles refObj={'About'} />
        <Behance timer={1.3}/>
      </div>

      
      <div className="about-container04">
        <div className="about-container05">
          
          <div className="about-container06">
            <span className="about-text01">About Us</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image1"
            />
          </div>
          <span className="about-text02">
            Welcome to Kester Studios - Your Creative Hub for Cutting-Edge
            Digital Solutions
          </span>
          <span className="about-text03">
            At Kester Studios, we are passionate about pushing the boundaries of
            creativity and technology. Meet Kester, our versatile and talented
            freelancer, who brings a wealth of skills and expertise to the
            table. With a strong foundation in game development, app design, web
            development, blockchain, bots, AI, software development, graphics,
            3D and 2D modeling, as well as animations, Kester is your go-to
            creative partner for all your digital needs.
          </span>
          <div className="about-container07">
            <img
              alt="image"
              src="/external/CEO Picture.png"
              className="about-image2"
            />
            <div className="about-container08">
              <span className="about-text04">
                Kester The CEO of Kester Studios &amp; Team
              </span>
              <span className="about-text05">
                We&apos;ll get the job done for you
              </span>
              <button type="button" className="about-button button">
                Hire us
              </button>
            </div>
          </div>
          <div className="about-container09">
            <span className="about-text06">Our Team</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image3"
            />
          </div>
          <div className="about-container10">
            <img
              alt="image"
              src="/external/member%201-1400w.png"
              className="about-image4"
            />
            <img
              alt="image"
              src="/external/member%202-1400w.png"
              className="about-image5"
            />
            <img
              alt="image"
              src="/external/member%203-1400w.png"
              className="about-image6"
            />
          </div>
          <span className="about-text07">
            <span>
              In the dynamic world of software development, our team stands out
              as the ultimate blend of talent. From backend and front-end
              experts to design wizards and mobile development aficionados,
              we&apos;ve got it all covered.
            </span>
            <br></br>
            <br></br>
            <span className="about-text11">Backend to Front-end:</span>
            <span>
              {' '}
              Our developers craft powerful backend systems while ensuring sleek
              front-end designs. They excel in PHP, Laravel, HTML, CSS, and
              JavaScript, making your digital dreams come true.
            </span>
            <br></br>
            <span className="about-text14">Mobile Marvels:</span>
            <span>
              {' '}
              For mobile apps, we&apos;ve got you covered with Flutter, React
              Native, Android Studio, and Xcode expertise. Your apps will run
              seamlessly on both Android and iOS platforms.
            </span>
            <br></br>
            <span className="about-text17">Swift and React Masters:</span>
            <span>
              {' '}
              Our Swift developers create stunning iOS apps with Xcode, while
              our React experts make your web and mobile apps responsive and
              dynamic.
            </span>
            <br></br>
            <span className="about-text20">Design Excellence:</span>
            <span>
              {' '}
              Our UI/UX designers aren&apos;t just about looks; they prioritize
              user experience, making your interfaces visually appealing and
              user-friendly.
            </span>
            <br className="about-text22"></br>
            <br></br>
            <span>
              Our team is your digital dream team, combining diverse skills to
              deliver exceptional solutions. Whether it&apos;s web or mobile
              development, count on us to exceed your expectations. Join us on
              our journey to redefine technology&apos;s limits, one line of code
              at a time.
            </span>
          </span>
        </div>
      </div>
      {/* <img
        alt="image"
        src="/external/scroll%20down-200h.png"
        className="about-image7"
      />
      <Link to="/contactus" className="about-navlink4">
        <img
          alt="image"
          src="/external/contact%20us-200w.png"
          className="about-image8"
        />
      </Link> */}
      
    </div>
    
  )
}

export default About
