import React, { useEffect, useRef } from 'react'
import '../views/home.css';
import { Link } from 'react-router-dom'

import gsap from 'gsap';

const TitlesX = (props) =>{

    const containerRef = useRef(null);

  useEffect(() => {
    const children = containerRef.current.children;

    const timeline = gsap.timeline({ defaults: { duration: 1, ease: 'power2.out' } });

    gsap.from(children, {
      y: -100,
      delay:0.4,
      opacity: 0,
      stagger: 0.2,
      onComplete: () => {
        // Animation is complete
      },
    });
  }, []);

  const handleButtonClick = () => {
    const HomeNav = 'About';
    sessionStorage.setItem('setter', HomeNav);
    // props.myFunction();
  };

    return(
        <div className="home-container3" ref={containerRef} style={{zIndex:26}}>
            {(props.refObj=='About')?<Link to="/" className="home-navlink" style={{color:'#6C0BDB', cursor:'pointer'}} onClick={handleButtonClick}>
              About
            </Link>:<Link to="/" className="home-navlink" style={{cursor:'pointer'}} onClick={handleButtonClick}>
              About
            </Link>}

            {(props.refObj=='Services')?<Link to="/services" className="home-navlink1" style={{color:'#6C0BDB', cursor:'pointer'}}>
              Services
            </Link>:<Link to="/services" className="home-navlink1">
              Services
            </Link>}

            {(props.refObj=='Testimonials')?<Link to="/testimonials" className="home-navlink2" style={{color:'#6C0BDB', cursor:'pointer'}}>
              Testimonials
            </Link>:<Link to="/testimonials" className="home-navlink2">
              Testimonials
            </Link>}

            {(props.refObj=='Contact')?<Link to="/contactus" className="home-navlink3" style={{color:'#6C0BDB', cursor:'pointer'}}>
              Contact Us
            </Link>:<Link to="/contactus" className="home-navlink3">
              Contact Us
            </Link>}
          </div>
    );
}

export default TitlesX;