import React,{useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './services.css'
import TitlesX from '../components/titlesX'
import './home.css'
import SiteName from '../components/sitename'
import Behance from '../components/Behance'
import customLogo from '../components/KstudiosLogo.png';
import MenuDropOthers from '../components/menuDropOthers';
import BouncingAnimation from '../components/BouncingAnimation';
import BouncingAnimationContact from '../components/BouncingAnimationContact';
import LoadingScreen from '../components/loading';



const Services = (props) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    // Triggered when an image or video is loaded
    // Check if all elements are loaded
    const allElementsLoaded = document.querySelectorAll('img, video').length === 0;

    if (allElementsLoaded) {
      // All elements are loaded, hide the loading overlay
      setTimeout(()=>{setLoading(false);},2000);
      
    }
  };

  const [MenuOpened, setMenuOpened] = useState(false);

  function MenuChanger() {
    setMenuOpened(!MenuOpened);
  }

  function Close(){
    handleButtonClick();
  }

  const buttonRef = useRef(null);
    const buttonRef2 = useRef(null);
    const [isRotated, setIsRotated] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const handleButtonClick = () => {
        const rotation = isRotated ? 0 : 45;
        const rotationXy = isRotated ? 0 : -45;
        const translateY = isMoved ? 0 : 8;
        const translateY2 = isMoved ? 0 : -8;

        gsap.to(buttonRef.current, {
            rotation: rotation,
            y: translateY,
            duration: 0.5,
        });

        gsap.to(buttonRef2.current, {
            rotation: rotationXy,
            y: translateY2,
            duration: 0.5,
        });

        setIsRotated(!isRotated); // Update state variables
        setIsMoved(!isMoved); // Update state variables
        MenuChanger();
        // console.log(isRotated);
        console.log(MenuOpened);

    };
    useEffect(()=>{
      gsap.fromTo('.home-imgXY', {
          y:-70
      }, {y:0, delay:0.4})
    },[]);

    useEffect(() => {
    

      gsap.fromTo('.about-text01', {x:150, opacity:0},{
        x:0,opacity:3, duration:1
      });
  
      gsap.fromTo('.about-image1', {x:120, opacity:0},{
        x:0,opacity:4, duration:1, delay:0.3
      });
    }, []);

  // First 

  useEffect(()=>{

    // Game Development 

    gsap.fromTo('.services-image01', {x:-800},{
      x:0, duration:1
    });

    gsap.fromTo('.services-container05', {x:+1200},{
      x:0, duration:1
    });

    /// Close Back 

    gsap.fromTo('.services-image01', {x:0},{
      x:-800,
      scrollTrigger: {
        trigger: '.firstS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.services-container05', {x:0},{
      x:+1200,
      scrollTrigger: {
        trigger: '.firstS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    // App Development 

    gsap.fromTo('.services-container13', {x:-1200}, {x:-1200});
    gsap.fromTo('.services-image06', {x:800}, {x:800});

    gsap.fromTo('.services-image06', {x:800},{
      x:0, 
      scrollTrigger: {
        trigger: '.secondS',
        start: 'top-=200px top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -800,
      },
    });

    gsap.fromTo('.services-container13', {x:-1200},{
      x:0, 
      scrollTrigger: {
        trigger: '.secondS',
        start: 'top-=200px top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -1200,
      },
    });

    /// Close Back App

    gsap.fromTo('.services-image06', {x:0},{
      x:800,
      scrollTrigger: {
        trigger: '.secondS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.services-container13', {x:0},{
      x:-1200,
      scrollTrigger: {
        trigger: '.secondS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    // Blockchain Development 
    
    gsap.fromTo('.services-container15', {x:1200}, {x:1200});
    gsap.fromTo('.services-image08', {x:-800}, {x:-800});

    gsap.fromTo('.services-image08', {x:-800},{
      x:0, 
      scrollTrigger: {
        trigger: '.thirdS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -800,
      },
    });

    gsap.fromTo('.services-container15', {x:1200},{
      x:0, 
      scrollTrigger: {
        trigger: '.thirdS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: 1200,
      },
    });

    /// Close Back Blocchain

    gsap.fromTo('.services-image08', {x:0},{
      x:-800,
      scrollTrigger: {
        trigger: '.thirdS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.services-container15', {x:0},{
      x:1200,
      scrollTrigger: {
        trigger: '.thirdS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    // Web Development 

    gsap.fromTo('.services-container17', {x:-1200}, {x:-1200});
    gsap.fromTo('.services-image10', {x:800}, {x:800});

    gsap.fromTo('.services-image10', {x:800},{
      x:0, 
      scrollTrigger: {
        trigger: '.fourthS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -800,
      },
    });

    gsap.fromTo('.services-container17', {x:-1200},{
      x:0, 
      scrollTrigger: {
        trigger: '.fourthS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -1200,
      },
    });

    /// Close Back Web

    gsap.fromTo('.services-image10', {x:0},{
      x:800,
      scrollTrigger: {
        trigger: '.fourthS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.services-container17', {x:0},{
      x:-1200,
      scrollTrigger: {
        trigger: '.fourthS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    // Animation 
    
    gsap.fromTo('.services-container19', {x:1200}, {x:1200});
    gsap.fromTo('.services-image12', {x:-800}, {x:-800});

    gsap.fromTo('.services-image12', {x:-800},{
      x:0, 
      scrollTrigger: {
        trigger: '.fifthS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -800,
      },
    });

    gsap.fromTo('.services-container19', {x:1200},{
      x:0, 
      scrollTrigger: {
        trigger: '.fifthS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: 1200,
      },
    });

    /// Close Back animation

    gsap.fromTo('.services-image12', {x:0},{
      x:-800,
      scrollTrigger: {
        trigger: '.fifthS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.services-container19', {x:0},{
      x:1200,
      scrollTrigger: {
        trigger: '.fifthS',
        start: 'bottom center', 
        end: 'bottom-=100px top', 
        // markers: true,
        scrub: true, 
      },
    });

    // UI UX Design 

    gsap.fromTo('.services-container21', {x:-1200}, {x:-1200});
    gsap.fromTo('.services-image14', {x:800}, {x:800});

    gsap.fromTo('.services-image14', {x:800},{
      x:0, 
      scrollTrigger: {
        trigger: '.sixS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -800,
      },
    });

    gsap.fromTo('.services-container21', {x:-1200},{
      x:0, 
      scrollTrigger: {
        trigger: '.sixS',
        start: 'top top', 
        end: 'top+=300px top', 
        // markers: true,
        scrub: true, 
        initial: -1200,
      },
    });

    /// Close Back UI UX Design

    // gsap.fromTo('.services-image14', {x:0},{
    //   x:600,
    //   scrollTrigger: {
    //     trigger: '.sixS',
    //     start: 'bottom center', 
    //     end: 'bottom-=100px top', 
    //     // markers: true,
    //     scrub: true, 
    //   },
    // });

    // gsap.fromTo('.services-container21', {x:0},{
    //   x:-1200,
    //   scrollTrigger: {
    //     trigger: '.sixS',
    //     start: 'bottom center', 
    //     end: 'bottom-=100px top', 
    //     // markers: true,
    //     scrub: true, 
    //   },
    // });

    // Progress Scroll 

    gsap.fromTo('.fill', {height:'0%'},{
      height:'100%',
      // ease: 'power1.easeInOut',
      scrollTrigger: {
        trigger: '.segement',
        start: 'top+=100 top', 
        end: 'bottom-=200 bottom', 
        // markers: true,
        scrub: 0.5,
        toggleActions: 'play reverse play reverse', 
      },
    });
  }, []);

  const scrollTop = () => {

    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth', // Smooth scrolling
    });

    // console.log("Top");
  };

  useEffect(()=>{
    setTimeout(()=>{scrollTop();},200);
  },[]);

  const [loadingx, setLoadingx] = useState(true);

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setLoadingx(false);
      clearTimeout(timer);
    }, 5000);


  });
  return (
    <div className="services-container">
      {(loadingx)?<div  style={{zIndex:36, background:'black', display:'flex', justifyContent:'center', alignItems:'center', position:'fixed', height:'100vh', width:'100%'}}>
        <img src="/external/2RNb.gif" alt="Loading" />
      </div>:null}
      <Helmet>
        <title>services - Kesterstudios</title>
        <meta property="og:title" content="services - Kesterstudios" />\
        <link
          rel="icon"
          href={customLogo}
          type="image/png"
        />
      </Helmet>
      {/* Zoomable  */}
      <BouncingAnimation/>
        <BouncingAnimationContact />
      <div className="services-container01">
      <video alt="image" src="/external/tunnel_-_27438 (1080p).mp4" autoplay="" loop class="full-video" muted></video>
      {(MenuOpened)?<MenuDropOthers close={Close} rColor={'services'}/>:null} 
        <div className="services-container02">
            <span className="about-text01">Our Services</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image1"
            />
        </div>

        {/* Scroll Down  */}
        <div className='scrolldown'>
          <div className='fill'></div>
        </div>
        <div className="services-container03">
          <div className="services-container04">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image01"
            />
            <div className="services-container05">
              <span className="services-text01">Game Development</span>
              <span className="services-text02">
                We&apos;ll build your 3d and 2d game using any engine or code of
                your choice. Unity, Unreal Game Engine, three.js, Godot,
                CryEngine, GameMaker Studio, Construct
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image02"
              />
            </div>
          </div>
        </div>
        <div className="services-container03">
          <div className="services-container04">
          
            <div className="services-container13">
              <span className="services-text06">App Development</span>
              <span className="services-text07">
                We&apos;ll build Android, IOS Apps and cross platform apps as
                well. With Android Studio, Swift UI, React native, Flutter
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image02"
              />
            </div>
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image06"
            />
          </div>
        </div>
        <div className="services-container03">
          <div className="services-container04">
          
          <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image08"
            />
            <div className="services-container15">
              <span className="services-text08">Blockchain Development</span>
              <span className="services-text09">
                Web3, Ethers, Hardhat, Truffle, and we work on several networks.
                Etheruem, BSC, Solana... and We create both smart contracts,
                Decentralized applications, NFTs, P2e games...
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image02"
              />
            </div>
          </div>
        </div>
        <div className="services-container03">
          <div className="services-container04">
            <div className="services-container17">
              <span className="services-text10">Web Development</span>
              <span className="services-text11">
                We create custom web applications. We do both development and
                designs. With Html, Css, JavaScript, Php, React, Vue, Next,
                Bootstrap, Laravel
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image02"
              />
            </div>
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image10"
            />
          </div>
        </div>
        <div className="services-container03">
          <div className="services-container04">
          
          <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image12"
            />
            <div className="services-container19">
              <span className="services-text12">
                3D Modeling &amp; Animation
              </span>
              <span className="services-text13">
                This includes 3d modeling, sculpting, texturing, rigging,
                animation, rendering, with these softwares: Zbrush, Sketchup,
                Blender, Maya, Cinema 4d, iClone, Unreal...
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image02"
              />
            </div>
          </div>
        </div>
        <div className="services-container03">
          <div className="services-container04">
          
            <div className="services-container21">
              <span className="services-text14">Ui Ux design</span>
              <span className="services-text15">
                We create stunning Ui Ux design for your app, website, and also
                help you to brand your entire content and visuals to ensure you
                meet the standard needed to impress your customers. We use Adobe
                Xd, Sketch, Figma
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image02"
              />
            </div>
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image14"
            />
          </div>
        </div>
      </div>
      <div className="services-container06">
        <SiteName />
        
        <TitlesX refObj={'Services'}/>
        
        <Behance timer={1.3}/>
        <div className='home-imgXY' onClick={handleButtonClick}>
            <img
              alt="image"
              src="/external/upMenu.png"
              className="home-imageXYZ"
              ref={buttonRef}
            />
            <img
              alt="image"
              src="/external/DownMenu.png"
              className="home-imageXYZ"
              ref={buttonRef2}
            />
          </div>
        
      </div>
      <div className='segement' >
        <div className='firstS'>
          
        </div>
        <div className='secondS'>

        </div>
        <div className='thirdS'>

        </div>
        <div className='fourthS'>

        </div>
        <div className='fifthS'>

        </div>
        <div className='sixS'>

        </div>
      </div>
      {/* <div className="services-container08">
        <div className="services-container09">
          <div className="services-container10">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image04"
            />
            <div className="services-container11">
              <span className="services-text04">Game Development</span>
              <span className="services-text05">
                We&apos;ll build your 3d and 2d game using any engine or code of
                your choice. Unity, Unreal Game Engine, three.js, Godot,
                CryEngine, GameMaker Studio, Construct
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image05"
              />
            </div>
          </div>
          <div className="services-container12">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image06"
            />
            <div className="services-container13">
              <span className="services-text06">App Development</span>
              <span className="services-text07">
                We&apos;ll build Android, IOS Apps and cross platform apps as
                well. With Android Studio, Swift UI, React native, Flutter
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image07"
              />
            </div>
          </div>
          <div className="services-container14">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image08"
            />
            <div className="services-container15">
              <span className="services-text08">Blockchain Development</span>
              <span className="services-text09">
                Web3, Ethers, Hardhat, Truffle, and we work on several networks.
                Etheruem, BSC, Solana... and We create both smart contracts,
                Decentralized applications, NFTs, P2e games...
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image09"
              />
            </div>
          </div>
          <div className="services-container16">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image10"
            />
            <div className="services-container17">
              <span className="services-text10">Web Development</span>
              <span className="services-text11">
                We create custom web applications. We do both development and
                designs. With Html, Css, JavaScript, Php, React, Vue, Next,
                Bootstrap, Laravel
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image11"
              />
            </div>
          </div>
          <div className="services-container18">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image12"
            />
            <div className="services-container19">
              <span className="services-text12">
                3D Modeling &amp; Animation
              </span>
              <span className="services-text13">
                This includes 3d modeling, sculpting, texturing, rigging,
                animation, rendering, with these softwares: Zbrush, Sketchup,
                Blender, Maya, Cinema 4d, iClone, Unreal...
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image13"
              />
            </div>
          </div>
          <div className="services-container20">
            <img
              alt="image"
              src="/external/servi.gif"
              style={{zIndex:10}}
              className="services-image14"
            />
            <div className="services-container21">
              <span className="services-text14">Ui Ux design</span>
              <span className="services-text15">
                We create stunning Ui Ux design for your app, website, and also
                help you to brand your entire content and visuals to ensure you
                meet the standard needed to impress your customers. We use Adobe
                Xd, Sketch, Figma
              </span>
              <img
                alt="image"
                src="/external/contactusx-600h.png"
                className="services-image15"
              />
            </div>
          </div>
        </div>
      </div> */}
      
    </div>
  )
}

export default Services
