import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import '../views/home.css'
import './typewriter.css'


const WritenText = ({ text, durationX }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;

    gsap.fromTo(
      textElement,
      { y:100 },
      { y:1, duration: 0.5, delay: durationX }
    );
  }, []);

  return <div className="typewriterX"><div ref={textRef} className="typing-textX">{text}</div></div>;
};

export default WritenText;