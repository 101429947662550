import React, { useEffect } from 'react'
import '../views/home.css';
import gsap from 'gsap';

const Behance = ({timer}) =>{

    useEffect(()=>{
        gsap.fromTo('.home-imgX', {
            y:-70
        }, {y:0, delay:timer})
    },[])
    return(
        <span className='home-imgX'>
            <a
        href="https://www.behance.net/kesteradeyelu/"
        target="_blank"
        rel="noreferrer noopener"
        className="home-link"
      >
        <img
          alt="image"
          src="/external/behance-200h.png"
          className="home-image"
        />
      </a></span>
    );
}

export default Behance;