import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import '../views/home.css'
import { Link } from 'react-router-dom'

function BouncingAnimationContact() {
  const boxRef = useRef(null);

  useEffect(() => {
    const box = boxRef.current;

    gsap.to(box, {
      y: -50,
      duration: 1,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    });
  }, []);

  return (
      <div ref={boxRef} className="home-image2">
        <Link to="/contactus" className="home-navlink4">
          <img
            alt="image"
            src="/external/contact%20us-1500h.png"
            className="home-image2"
          />
        </Link>
        </div>
  );
}

export default BouncingAnimationContact;
