import React, { useEffect, useRef } from 'react'
import '../views/home.css';
import { Link } from 'react-router-dom'

import gsap from 'gsap';

const Titles = (props) =>{

  const containerRef = useRef(null);

  useEffect(() => {
    const children = containerRef.current.children;

    const timeline = gsap.timeline({ defaults: { duration: 1, ease: 'power2.out' } });

    gsap.from(children, {
      y: -100,
      delay:0.4,
      opacity: 0,
      stagger: 0.2,
      onComplete: () => {
        // Animation is complete
      },
    });
  }, []);

  const handleButtonClick = () => {
    props.myFunction();
      const HomeNav = 'About';
      sessionStorage.setItem('setter', HomeNav);
  };

    return(
        <div className="home-container3" ref={containerRef} style={{zIndex:26}}>
            {(props.refObj=='About')?<div className="home-navlink" style={{color:'#6C0BDB', cursor:'pointer'}} onClick={handleButtonClick}>
              About
            </div>:<div className="home-navlink" style={{cursor:'pointer'}} onClick={handleButtonClick}>
              About
            </div>}
            <Link to="/services" className="home-navlink1">
              Services
            </Link>
            <Link to="/testimonials" className="home-navlink2">
              Testimonials
            </Link>
            <Link to="/contactus" className="home-navlink3">
              Contact Us
            </Link>
          </div>
    );
}

export default Titles;