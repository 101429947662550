import React,{useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import gsap from 'gsap';


import './testimonials.css'
import TitlesX from '../components/titlesX'

import SiteName from '../components/sitename'
import customLogo from '../components/KstudiosLogo.png';
import Behance from '../components/Behance'
import MenuDropOthers from '../components/menuDropOthers';
import BouncingAnimationContact from '../components/BouncingAnimationContact';

const Testimonials = (props) => {

  const [MenuOpened, setMenuOpened] = useState(false);

  function MenuChanger() {
    setMenuOpened(!MenuOpened);
  }

  function Close(){
    handleButtonClick();
  }

  const buttonRef = useRef(null);
    const buttonRef2 = useRef(null);
    const [isRotated, setIsRotated] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const handleButtonClick = () => {
        const rotation = isRotated ? 0 : 45;
        const rotationXy = isRotated ? 0 : -45;
        const translateY = isMoved ? 0 : 8;
        const translateY2 = isMoved ? 0 : -8;

        gsap.to(buttonRef.current, {
            rotation: rotation,
            y: translateY,
            duration: 0.5,
        });

        gsap.to(buttonRef2.current, {
            rotation: rotationXy,
            y: translateY2,
            duration: 0.5,
        });

        setIsRotated(!isRotated); // Update state variables
        setIsMoved(!isMoved); // Update state variables
        MenuChanger();
        // console.log(isRotated);
        console.log(MenuOpened);

    };
    useEffect(()=>{
      gsap.fromTo('.home-imgXY', {
          y:-70
      }, {y:0, delay:0.4})
    },[])

    useEffect(() => {
    

      gsap.fromTo('.about-text01', {x:150, opacity:0},{
        x:0,opacity:3, duration:1
      });
  
      gsap.fromTo('.about-image1', {x:120, opacity:0},{
        x:0,opacity:4, duration:1, delay:0.3
      });

      // Testimonials 

      gsap.fromTo('.testimonials-image1', {x:-600},{
        x:0, duration:1
      });
  
      gsap.fromTo('.testimonials-container4', {x:+1200},{
        x:0, duration:1
      });

      // Page Reload

      gsap.fromTo('.testimonials-text1', {y:-120, opacity:0},{
        y:0,opacity:1, duration:1, delay:1
      });

      gsap.fromTo('.testimonials-text2', {y:120, opacity:0},{
        y:0,opacity:1, duration:1, delay:1.3
      });

      gsap.fromTo('.testimonials-text3', {y:120, opacity:0},{
        y:0,opacity:1, duration:1, delay:1.6
      });

      gsap.fromTo('.testimonials-container4', {scale:0.5, opacity:0},{
        scale:1,opacity:1, duration:1, delay:0.5
      });

      // 
    },[]);
  
  const [testimony, setTestimony] = useState(0);
  const [Name, setName] = useState("Rorien");
  const [Description, setDescription] = useState(`Kester has always done my hair models for me. and his jobs are always excellent and perfect`);
  const [Country, setCountry] = useState("United States");
  const [Image, setImage] = useState("/external/ellipse%209-200h.png");

  const checkData = (e) =>{
    if(e==1){
      setName("Eiron");
      setCountry("United States");
      setDescription("Recommended their studio severally. The best I found online");
      setImage("/external/pic1.png");
    }else if (e==2) {
      setName("Hummi Tech");
      setCountry("India");
      setImage("/external/pic4.png");
      setDescription("Worked with him severally. He always delivers the best quality and very vast in several areas");
    }else if (e==3) {
      setName("Ricardo Celis");
      setCountry("United States");
      setImage("/external/pic2.png");
      setDescription("Kester did an excellent job for me. Recommending him to everyone out there");
    }else if (e==4) {
      setName("Miguel Peraza");
      setCountry("Italy");
      setDescription("My best Blockchain developer and metaverse creator. He's exceptional in his work.");
      setImage("/external/pic3.png");
    } else {
      setName("Rorien");
      setCountry("United States");
      setDescription("Kester has always done my hair models for me. and his jobs are always excellent and perfect");
      setImage("/external/ellipse%209-200h.png");
    }

    gsap.fromTo('.testimonials-text1', {y:-120, opacity:0},{
      y:0,opacity:1, duration:1, delay:1
    });

    gsap.fromTo('.testimonials-text2', {y:120, opacity:0},{
      y:0,opacity:1, duration:1, delay:1.3
    });

    gsap.fromTo('.testimonials-text3', {y:120, opacity:0},{
      y:0,opacity:1, duration:1, delay:1.6
    });

    gsap.fromTo('.testimonials-container4', {scale:0.5, opacity:0},{
      scale:1,opacity:1, duration:1, delay:0.5
    });
  }

  const next = () =>{
    if(testimony<=3){
      setTestimony(testimony+1);
      checkData(testimony+1);
    }else{
      setTestimony(0);
      checkData(0);
    }
    
  }

  const prev = () =>{
    if(testimony>=1){
      setTestimony(testimony-1);
      checkData(testimony-1);
    }else{
      setTestimony(4);
      checkData(4);
    }

  }

  const objectRef = useRef(null);

  useEffect(() => {
    // Create a GSAP animation for the rotating object
    const rotationAnimation = gsap.to(objectRef.current, {
      rotation: 360, // Rotate 360 degrees
      repeat: -1, // Infinite loop
      duration: 2, // 2 seconds per rotation
      ease: 'linear', // Linear animation (constant speed)
    });

    return () => {
      rotationAnimation.kill(); // Stop the animation
    };
  }, []);

  const objectRef2 = useRef(null);

  useEffect(() => {
    // Create a GSAP animation for the rotating object
    const rotationAnimation = gsap.to(objectRef2.current, {
      rotation: -720, // Rotate 360 degrees
      repeat: -1, // Infinite loop
      duration: 2, // 2 seconds per rotation
      ease: 'linear', // Linear animation (constant speed)
    });

    return () => {
      rotationAnimation.kill(); // Stop the animation
    };
  }, []);

  const objectRef3 = useRef(null);

  useEffect(() => {
    // Create a GSAP animation for the rotating object
    const rotationAnimation = gsap.to(objectRef3.current, {
      rotation: 360, // Rotate 360 degrees
      repeat: -1, // Infinite loop
      duration: 2, // 2 seconds per rotation
      ease: 'linear', // Linear animation (constant speed)
    });

    return () => {
      rotationAnimation.kill(); // Stop the animation
    };
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setLoading(false);
      clearTimeout(timer);
    }, 5000);


  });

  return (
    <div className="testimonials-container">
      {(loading)?<div  style={{zIndex:36, background:'black', display:'flex', justifyContent:'center', alignItems:'center', position:'fixed', height:'100vh', width:'100%'}}>
        <img src="/external/2RNb.gif" alt="Loading" />
      </div>:null}
      <Helmet>
        <title>testimonials - Kesterstudios</title>
        <meta property="og:title" content="testimonials - Kesterstudios" />
        <link
          rel="icon"
          href={customLogo}
          type="image/png"
        />
      </Helmet>
      {/* Zoomable  */}
        <BouncingAnimationContact />
      <div className="testimonials-container1">
      <video alt="image" src="/external/tunnel_-_27438 (1080p).mp4" autoplay="" loop class="full-video" muted></video>

      {(MenuOpened)?<MenuDropOthers close={Close} rColor={'testimonials'}/>:null} 
      
        <div className="services-container02">
            <span className="about-text01">Our Testimonies</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image1"
            />
        </div>
        <div className="testimonials-container3">
          <img
            alt="image"
            src="/external/testi.gif"
            className="testimonials-image1"
          />
          {/* <h2 style={{color:'white'}}>{testimony}</h2> */}
          <div className="testimonials-container4" >
            <div className="testimonials-container5" ref={objectRef}>
              <div className="testimonials-container6" ref={objectRef2}>
                <img
                  alt="image"
                  src={Image}
                  className="testimonials-image2"
                  ref={objectRef3}
                />
              </div>
            </div>
            <span className="testimonials-text1">{Name}</span>
            <span className="testimonials-text2">
              {Description}
            </span>
            <span className="testimonials-text3">{Country}</span>
            <img
              alt="image"
              src="/external/left-500h.png"
              className="testimonials-image3"
              onClick={prev}
            />
            <img
              alt="image"
              src="/external/right-500h.png"
              className="testimonials-image4"
              onClick={next}
            />
          </div>
        </div>
      </div>
      <div className="testimonials-container7">
        <SiteName />
        
        <TitlesX refObj={'Testimonials'}/>
        
        <Behance timer={1.3}/>
        <div className='home-imgXY' onClick={handleButtonClick}>
            <img
              alt="image"
              src="/external/upMenu.png"
              className="home-imageXYZ"
              ref={buttonRef}
            />
            <img
              alt="image"
              src="/external/DownMenu.png"
              className="home-imageXYZ"
              ref={buttonRef2}
            />
          </div>
      </div>
    </div>
  )
}

export default Testimonials
