import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './home.css'
import BouncingAnimation from '../components/BouncingAnimation'
import BouncingAnimationContact from '../components/BouncingAnimationContact'
import TypewritingAnimation from '../components/TypewritingAnimation'
import SiteName from '../components/sitename'
import Titles from '../components/titles'
import Behance from '../components/Behance'
import customLogo from '../components/KstudiosLogo.png';
import SpriteAnimation from '../components/SpriteAnimation';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import About from './about';
import WritenText from '../components/writenText'
import SideMenu from '../components/sideMenu'
import MenuDrop from '../components/menuDrop'
import LoadingScreen from '../components/loading'
gsap.registerPlugin(ScrollTrigger);

const Home = (props) => {

  const zoomElementRefX = useRef(null);

  let OpenedX = false;

  let OpenedY = false;

  useEffect(() => {
    

    gsap.fromTo('.home-container1', {scale:1, zIndex:25},{
      scale: 7, // Initial scale
      zIndex:25,
      scrollTrigger: {
        trigger: '.scroll-trigger-container',
        start: 'top top', // Trigger point
        end: 'bottom-=25% bottom', // End point
        // markers: true,
        scrub: true, // Smooth scroll animation
        onLeave: () => {
          // This callback is called when the pinning animation is finished
          gsap.to('.home-container1', { zIndex: 0.1, duration: 0.1 }); // Increase z-index to 1
          CloseX();
          
        },
      },
    });
  }, []);

  

  const pinRef = useRef(null);

  useEffect(() => {
    const pinElement = pinRef.current;

    if (!pinElement) return;

    const pinDuration = 2; // Duration of pinning animation in seconds
    const releaseY = 400; // Y-position to release the pinned element
    const screenHeight = window.innerHeight;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: pinElement,
        start: 'top top',
        end: `${screenHeight}px top`,
        pin: true,
        // markers: true, // Add markers to visualize the trigger
        
      },
    });

    // Add animations to the timeline
    tl.from(pinElement, {  duration: pinDuration });

    return () => {
      // Clean up and kill the scrollTrigger on unmount
      tl.scrollTrigger.kill(true);
    };
  }, []);

  // Scroll To 

  const targetRef = useRef(null);

  const scrollToElement = () => {
    if (targetRef.current) {
      const elementPosition = targetRef.current.getBoundingClientRect();
      window.scrollTo({
        top: elementPosition.top + window.scrollY, // Adjust for current scroll position
        behavior: 'smooth', // Smooth scrolling
      });
    }

    // console.log("Bottom");

  };

  // Top Scroll 

  const scrollTop = () => {

    const HomeNav = 'Home';
    sessionStorage.setItem('setter', HomeNav);

    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth', // Smooth scrolling
    });

    // console.log("Top");
  };

  
  useEffect(()=>{
    const Menu = sessionStorage.getItem('setter');
    // console.log(Menu);
    if(Menu=="About"){
      setTimeout(()=>{scrollToElement();}, 200);
      
    }else{
      
      setTimeout(()=>{scrollTop();}, 200);
    }
  },[]);

  // About text movement 
  useEffect(() => {
    

    gsap.fromTo('.about-text01', {x:150, opacity:0},{
      x:0,opacity:3,
      scrollTrigger: {
        trigger: '.about-text01',
        start: 'bottom+=900px bottom', // Trigger point
        end: 'bottom+=900px center-=20%', // End point
        // markers: true,
        scrub: true, // Smooth scroll animation
      },
    });

    gsap.fromTo('.about-image1', {x:120, opacity:0},{
      x:0,opacity:4,
      scrollTrigger: {
        trigger: '.about-image1',
        start: 'bottom+=900px bottom', // Trigger point
        end: 'bottom+=900px center', // End point
        // markers: true,
        scrub: true, // Smooth scroll animation
      },
    });

    gsap.fromTo('.about-text06', {x:150},{
      x:0,
      scrollTrigger: {
        trigger: '.about-text06',
        start: 'bottom+=900px bottom', // Trigger point
        end: 'bottom+=900px center-=20%', // End point
        // markers: true,
        scrub: true, // Smooth scroll animation
      },
    });

    gsap.fromTo('.about-image3', {x:120},{
      x:0,
      scrollTrigger: {
        trigger: '.about-image3',
        start: 'bottom+=900px bottom', 
        end: 'bottom+=900px center', 
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.about-text06Y', {x:150},{
      x:0,
      scrollTrigger: {
        trigger: '.about-text06Y',
        start: 'bottom+=900px bottom', // Trigger point
        end: 'bottom+=900px center-=20%', // End point
        // markers: true,
        scrub: true, // Smooth scroll animation
      },
    });

    gsap.fromTo('.about-image3Y', {x:120},{
      x:0,
      scrollTrigger: {
        trigger: '.about-image3Y',
        start: 'bottom+=900px bottom', 
        end: 'bottom+=900px center', 
        // markers: true,
        scrub: true, 
      },
    });
  }, []);

  // Text 01 Animation 
  const itemRef = useRef(null);
  useEffect(() => {
    const item = itemRef.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 02 Animation 
  const itemRef2 = useRef(null);
  useEffect(() => {
    const item = itemRef2.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 03 Animation 
  const itemRef3 = useRef(null);
  useEffect(() => {
    const item = itemRef3.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 04 Animation 
  const itemRef4 = useRef(null);
  useEffect(() => {
    const item = itemRef4.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);


  // Text 05 Animation 
  const itemRef5 = useRef(null);
  useEffect(() => {
    const item = itemRef5.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 06 Animation 
  const itemRef6 = useRef(null);
  useEffect(() => {
    const item = itemRef6.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 07 Animation 
  const itemRef7 = useRef(null);
  useEffect(() => {
    const item = itemRef7.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 08 Animation 
  const itemRef8 = useRef(null);
  useEffect(() => {
    const item = itemRef8.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);

  // Text 08 Animation 
  const itemRef9 = useRef(null);
  useEffect(() => {
    const item = itemRef9.current;

    const tl = gsap.timeline({ paused: true });
    tl.fromTo(item, { y: 100, opacity: 0, duration: 0.5 },{y:0, opacity:1.5});

    ScrollTrigger.create({
      trigger: item,
      start: 'top+=700px center+=100', 
      end: 'bottom+=700px top',
      animation: tl,
      // markers: true, 
      toggleActions: 'play none none reverse',
    });
  }, []);
  
  useEffect(() => {
    gsap.fromTo('.about-image2', {x:320},{
      x:0,
      scrollTrigger: {
        trigger: '.about-image2',
        start: 'bottom+=600px bottom', 
        end: 'bottom+=600px center-=20%',
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.about-container08', {x:-320},{
      x:0,
      scrollTrigger: {
        trigger: '.about-container08',
        start: 'bottom+=600px bottom', 
        end: 'bottom+=600px center',
        // markers: true,
        scrub: true, 
      },
    });

    // Slided Images 

    gsap.fromTo('.about-image4', {x:350},{
      x:0,
      scrollTrigger: {
        trigger: '.about-image4',
        start: 'bottom+=600px bottom', 
        end: 'bottom+=600px center-=20%',
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.about-image6', {x:-350},{
      x:0,
      scrollTrigger: {
        trigger: '.about-image6',
        start: 'bottom+=600px bottom', 
        end: 'bottom+=600px center',
        // markers: true,
        scrub: true, 
      },
    });

    gsap.fromTo('.about-image5', {scale:1.3},{
      scale:1,
      scrollTrigger: {
        trigger: '.about-image6',
        start: 'bottom+=600px bottom', 
        end: 'bottom+=600px center',
        // markers: true,
        scrub: true, 
      },
    });

  }, []);

  const objectRef = useRef(null);

  useEffect(() => {
    // Create a GSAP animation for the rotating object
    const rotationAnimation = gsap.to(objectRef.current, {
      rotation: 360, // Rotate 360 degrees
      repeat: -1, // Infinite loop
      duration: 2, // 2 seconds per rotation
      ease: 'linear', // Linear animation (constant speed)
    });

    return () => {
      rotationAnimation.kill(); // Stop the animation
    };
  }, []);

  const objectRef2 = useRef(null);

  useEffect(() => {
    // Create a GSAP animation for the rotating object
    const rotationAnimation = gsap.to(objectRef2.current, {
      rotation: -720, // Rotate 360 degrees
      repeat: -1, // Infinite loop
      duration: 2, // 2 seconds per rotation
      ease: 'linear', // Linear animation (constant speed)
    });

    return () => {
      rotationAnimation.kill(); // Stop the animation
    };
  }, []);

  const objectRef3 = useRef(null);

  useEffect(() => {
    // Create a GSAP animation for the rotating object
    const rotationAnimation = gsap.to(objectRef3.current, {
      rotation: 360, // Rotate 360 degrees
      repeat: -1, // Infinite loop
      duration: 2, // 2 seconds per rotation
      ease: 'linear', // Linear animation (constant speed)
    });

    return () => {
      rotationAnimation.kill(); // Stop the animation
    };
  }, []);


  // Stagger Animation Skills 

  const listedRef = useRef(null);

  useEffect(() => {
    const children = listedRef.current.children;
    const timeline = gsap.timeline({ defaults: { duration: 1, ease: 'power2.out' } });

    const handleScroll = () => {
      if (window.scrollY >= listedRef.current.offsetTop) {
        // Start the animation when the specified element is in the viewport
        gsap.from(children, {
          y: -100,
          delay: 0.6,
          opacity: 0,
          stagger: 0.2,
          onComplete: () => {
            // Animation is complete
          },
        });
        // Remove the scroll event listener to prevent animation re-trigger
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up the scroll event listener
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const myButtonRef = useRef(null);

  const [MenuOpened, setMenuOpened] = useState(false);
  const [MenuOpenedX, setMenuOpenedX] = useState(false);
  const [ValueState, setValueState] = useState(false);

  function MenuChanger() {
    setMenuOpened(!MenuOpened);
    OpenedX = !OpenedX;
  }

  function MenuChangerX() {
    setMenuOpenedX(!MenuOpenedX);
    OpenedY = !OpenedY;
  }
  function Close(){
    handleButtonClick();
  }
  function CloseX(){
    // console.log("Win");
    console.log(OpenedX);
    if(MenuOpened==true){
      handleButtonClick();
      // console.log("WinX");
    }
  }

  useEffect(()=>{
    gsap.fromTo('.home-imgXY', {
        y:-70
    }, {y:0, delay:0.4})
},[])

    const buttonRef = useRef(null);
    const buttonRef2 = useRef(null);
    const [isRotated, setIsRotated] = useState(false);
    const [isMoved, setIsMoved] = useState(false);

    const handleButtonClick = () => {
        const rotation = isRotated ? 0 : 45;
        const rotationXy = isRotated ? 0 : -45;
        const translateY = isMoved ? 0 : 8;
        const translateY2 = isMoved ? 0 : -8;

        gsap.to(buttonRef.current, {
            rotation: rotation,
            y: translateY,
            duration: 0.5,
        });

        gsap.to(buttonRef2.current, {
            rotation: rotationXy,
            y: translateY2,
            duration: 0.5,
        });

        setIsRotated(!isRotated); // Update state variables
        setIsMoved(!isMoved); // Update state variables
        MenuChanger();
        // console.log(isRotated);
        console.log(MenuOpened);

    };

    const buttonRef3 = useRef(null);
    const buttonRef4 = useRef(null);
    const [isRotatedX, setIsRotatedX] = useState(false);
    const [isMovedX, setIsMovedX] = useState(false);

    const handleButtonClickX = () => {
        const rotationz = isRotatedX ? 0 : 45;
        const rotationXyz = isRotatedX ? 0 : -45;
        const translateYz = isMovedX ? 0 : 8;
        const translateY2z = isMovedX ? 0 : -8;

        gsap.to(buttonRef3.current, {
            rotation: rotationz,
            y: translateYz,
            duration: 0.5,
        });

        gsap.to(buttonRef4.current, {
            rotation: rotationXyz,
            y: translateY2z,
            duration: 0.5,
        });

        setIsRotatedX(!isRotatedX); // Update state variables
        setIsMovedX(!isMovedX); // Update state variables
        MenuChangerX();
        console.log(isRotatedX);
        console.log(isMovedX);
    };

    const boxRef = useRef(null);

  useEffect(() => {

    gsap.to('.home-image4', {
      y: -8,
      duration: 1.5,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    });
    gsap.to('.home-image3', {
      y: -7,
      duration: 1.2,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    });
  }, []);
    // home-image4 Waggle 

  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const timer = setTimeout(()=>{
      setLoading(false);
      clearTimeout(timer);
    }, 5000);


  });
  return (
    <div className="home-container">
      {(loading)?<div  style={{zIndex:36, background:'black', display:'flex', justifyContent:'center', alignItems:'center', position:'fixed', height:'100vh', width:'100%'}}>
        <img src="/external/2RNb.gif" alt="Loading" />
      </div>:null}
      
      <Helmet>
        <title>Kesterstudios</title>
        <meta property="og:title" content="Kesterstudios" />
        <link
          rel="icon"
          href={customLogo}
          type="image/png"
        />
        <meta name="description" content="Your go-to destination for website development, design, game development, app development, blockchain development, and 3D artistry." />
        <meta name="keywords" content="website developer, website designer, game developer, app developer, blockchain developer, 3D artist, 3d animator" />
        <meta property="og:title" content="Kester Studios - Home of Development and Designs" />
        <meta property="og:description" content="Your go-to destination for website development, design, game development, app development, blockchain development, and 3D artistry." />
        {/* Add your logo or an image representing your studio */}
        <meta property="og:image" content="https://kesterstudios.com/external/lady%20right.gif" />
        <meta property="og:url" content="https://kesterstudios.com" />
        <meta name="twitter:title" content="Kester Studios - Home of Development and Designs" />
        <meta name="twitter:description" content="Your go-to destination for website development, design, game development, app development, blockchain development, and 3D artistry." />
        <meta name="twitter:image" content="https://kesterstudios.com/external/lady%20right.gif" />
        <meta name="twitter:card" content="https://kesterstudios.com/external/lady%20right.gif" />
        <link rel="canonical" href="https://kesterstudios.com" />
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1347391196051277');
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>
        {/* Zoomable  */}
        <BouncingAnimation/>
        <BouncingAnimationContact />
        
        
        
        
      <div className="home-container1" style={{zIndex:20}} >


        <div className="home-container2" >
          {/* <span className="home-text">Kester Studios</span> */}
          <SiteName timer={0.2} />
          <Titles myFunction={scrollToElement} />
          {/* <Titles refObj=''/> */}
          <Behance timer={1.3}/>

          <div className='home-imgXY' onClick={handleButtonClick}>
            <img
              alt="image"
              src="/external/upMenu.png"
              className="home-imageXYZ"
              ref={buttonRef}
            />
            <img
              alt="image"
              src="/external/DownMenu.png"
              className="home-imageXYZ"
              ref={buttonRef2}
            />
          </div>
        </div>
        {(MenuOpened)?<MenuDrop close={Close} about={scrollToElement} rColor={'home'}/>:null} 
        
        {/* <Link to="/contactus" className="home-navlink4">
          <img
            alt="image"
            src="/external/contact%20us-1500h.png"
            className="home-image2"
          />
        </Link> */}
        <img
          alt="image"
          src="/external/LeftCat.gif"
          className="home-image3"
          onClick={scrollToElement}
        />
        {/* <SpriteAnimation /> */}
        <img
          alt="image"
          src="/external/lady right.gif"
          className="home-image4" 
        />
        <span className="home-text1">
            {/* &quot;All you need in development is available @ our Studio.&quot; */}
            <TypewritingAnimation text="&quot;All you need in development is available in our Studio&quot;" durationX={2.3} className="home-text1"/>
            <WritenText text="&quot;All you need in development is available in our Studio&quot;" durationX={2.3} className="home-text1" />
            {/* <TypewritingAnimation text="This is a typewriting animation." /> */}
          <br></br>
          <span>
          <TypewritingAnimation text="Game, App, Web, Blockchain, Bots, Ai, Softwares, Graphics, 3d, 2d,
            modeling &amp; Animations" durationX={3.4} className="home-text1"/>
            <WritenText  text="Game, App, Web, Blockchain, Bots, Ai, Softwares, Graphics, 3d, 2d,
            modeling &amp; Animations" durationX={3.4} className="home-text1"/>

            
          </span>
          <br></br>
        </span>
      </div>
      
      

      <div className="scroll-trigger-container" style={{height:'200vh', width:'98vw', position:'absolute', top:0}} >
        </div>
      {/* <About className='abouter' id='targetSection' />
       */}

<div className="about-container"  ref={pinRef}>
      
{(MenuOpenedX)?<MenuDrop close={handleButtonClickX} about={scrollToElement} home={scrollTop}  rColor={'about'}/>:null} 
      {/* <Helmet>
        <title>about - Kesterstudios</title>
        <meta property="og:title" content="about - Kesterstudios" />
      </Helmet> */}
      <div className="about-container01">
        <div className='aboutInternal'>
        <video
          alt="image"
          src="/external/Promotion Video.mp4"
          autoPlay
          muted
          loop
          className="full-video"
        />
        </div>
      </div>
      
      <div className="about-container02">

      <span className="home-text" style={{cursor:'pointer'}}><div className="about-navlink" onClick={scrollTop}>Kester Studios</div></span>
      {/* <SiteName timer={0.2} scrollUp={scrollTop} /> */}
        <Titles refObj={'About'} />
        <Behance timer={1.3}/>
        <div className='home-imgXY' onClick={handleButtonClickX}>
            <img
              alt="image"
              src="/external/upMenu.png"
              className="home-imageXYZ"
              ref={buttonRef3}
            />
            <img
              alt="image"
              src="/external/DownMenu.png"
              className="home-imageXYZ"
              ref={buttonRef4}
            />
          </div>
      </div>

      
      <div className="about-container04">
        <div className="about-container05" ref={targetRef}>
          
          <div className="about-container06" >
            <span className="about-text01">About Us</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image1"
            />
          </div>
          <span className="about-text02" ref={itemRef}>
            Welcome to Kester Studios - Your Creative Hub for Cutting-Edge
            Digital Solutions
          </span>
          <span className="about-text03" ref={itemRef2}>
            At Kester Studios, we are passionate about pushing the boundaries of
            creativity and technology. Meet Kester, our versatile and talented
            freelancer, who brings a wealth of skills and expertise to the
            table. With a strong foundation in game development, app design, web
            development, blockchain, bots, AI, software development, graphics,
            3D and 2D modeling, as well as animations, Kester is your go-to
            creative partner for all your digital needs.
          </span>
          <div className="about-container07">
          <img
          alt="image"
          src="/external/lady right.gif"
          className="home-image4X" 
        />
            <img
              alt="image"
              src="/external/CEO Picture.png"
              className="about-image2"
            />
            <div className="about-container08">
              <span className="about-text04">
                Kester The CEO of Kester Studios &amp; Team
              </span>
              <span className="about-text05">
                We&apos;ll get the job done for you
              </span>
              <Link to="/contactus" ><button type="button" className="about-button button">
                Hire us
              </button></Link>
            </div>
          </div>
          <div className="about-container09">
            <span className="about-text06">Our Team</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image3"
            />
          </div>
          <div className="about-container10">
            <img
              alt="image"
              src="/external/member 1T.png"
              className="about-image4"
            />
            <img
              alt="image"
              src="/external/member 2.png"
              className="about-image5"
            />
            <img
              alt="image"
              src="/external/member 3T.png"
              className="about-image6"
            />
          </div>
          <span className="about-text07">
            <div ref={itemRef3}>
              In the dynamic world of software development, our team stands out
              as the ultimate blend of talent. From backend and front-end
              experts to design wizards and mobile development aficionados,
              we&apos;ve got it all covered.
            </div>
            <br></br>
            <br></br>

            <div ref={itemRef4}>
              <span className="about-text11">Backend to Front-end:</span>
              <span>
                {' '}
                Our developers craft powerful backend systems while ensuring sleek
                front-end designs. They excel in PHP, Laravel, HTML, CSS, and
                JavaScript, making your digital dreams come true.
              </span>
            </div>
            <br></br>

            <div ref={itemRef5}>
              <span className="about-text14">Mobile Marvels:</span>
              <span>
                {' '}
                For mobile apps, we&apos;ve got you covered with Flutter, React
                Native, Android Studio, and Xcode expertise. Your apps will run
                seamlessly on both Android and iOS platforms.
              </span>
            </div>
            <br></br>

            <div ref={itemRef6}>
              <span className="about-text17">Swift and React Masters:</span>
              <span>
                {' '}
                Our Swift developers create stunning iOS apps with Xcode, while
                our React experts make your web and mobile apps responsive and
                dynamic.
              </span>
            </div>
            <br></br>

            <div ref={itemRef7}>
              <span className="about-text20">Design Excellence:</span>
              <span>
                {' '}
                Our UI/UX designers aren&apos;t just about looks; they prioritize
                user experience, making your interfaces visually appealing and
                user-friendly.
              </span>
            </div>
            <br className="about-text22"></br>
            <br></br>

            <div ref={itemRef9}>
              <span className="about-text20">3D Experts:</span>
              <span>
                {' '}
                Our team designs any kind of 3d character, product, photorealistic images and VFX for Unreal, Unity, Blender and any other platform you need it for.
              </span>
            </div>
            <br className="about-text22"></br>
            <br></br>

            <div ref={itemRef8}>
              Our team is your digital dream team, combining diverse skills to
              deliver exceptional solutions. Whether it&apos;s web or mobile
              development, count on us to exceed your expectations. Join us on
              our journey to redefine technology&apos;s limits, one line of code
              at a time.
            </div>
          </span>

          {/* List of services  */}
          <span className="about-text06Y">Our Skills</span>
            <img
              alt="image"
              src="/external/bar-200h.png"
              className="about-image3Y"
            />
          <div className='about-container07' style={{ paddingTop:'20px', paddingBottom:'20px', marginBottom:'80px'}}>
          
            <div className='leftH' ref={listedRef}>
              <div className='textW'>

                <div className='seParat'>

                  <span>Html, Css, JavaScript, Bootstrap...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>React, Vue, Next, Tailwind, UI UX...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>Blockchain, Web3, Ethers, Dapps...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='whiteX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>Metaverse, Webgl, NFts...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='whiteX'></div>
                    <div className='whiteX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>Unity, Unreal Engine, Three.js...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='whiteX'></div>
                    <div className='whiteX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>Blender, Zbrush, Sketchup...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>React native, Swift, Android St...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='whiteX'></div>
                  </span>

                </div>
              </div>

              <div className='textW'>

                <div className='seParat'>

                  <span>Telegram Bot, Trading Bot, Ai, python, java...</span>

                  <span className='ratingBox'>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='purpleX'></div>
                    <div className='whiteX'></div>
                    <div className='whiteX'></div>
                  </span>

                </div>
              </div>
                
            </div>
            <div className='rightH'>
              <div className="rightRound" ref={objectRef}>
                <div className="rightRoundS2" ref={objectRef2}>
                  <div className="rightRoundS3" ref={objectRef3}>
                    
                    </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      
    </div>

       {/* End About  */}
    </div>
  )
}

export default Home
