import React, {useRef, useEffect } from 'react'
import '../views/home.css';
import gsap from 'gsap';
import './menuDrop.css';
import { Link } from 'react-router-dom'


const MenuDrop = (props) =>{

    function Close(){
        props.close();
    }

    const handleButtonClick = () => {
        props.close();
        props.about();
          const HomeNav = 'About';
          sessionStorage.setItem('setter', HomeNav);
    };

    const handleButtonHome = () => {
        props.close();
        props.home();
          const HomeNav = 'Home';
          sessionStorage.setItem('setter', HomeNav);
    };

    const containerRef = useRef(null);

    useEffect(() => {
        const children = containerRef.current.children;

        const timeline = gsap.timeline({ defaults: { duration: 1, ease: 'power2.out' } });

        gsap.from(children, {
        y: -100,
        delay:0.4,
        opacity: 0,
        stagger: 0.2,
        onComplete: () => {
            // Animation is complete
        },
        });
    }, []);
    

    return(
        <div className='menu-container'>
            {/* <h1>Hello World</h1> */}
            <div className='secureArea'>
                {/* <img
                alt="image"
                src="/external/Kstudios Logo.png"
                className='menuLogo' /> */}
                <div className='sPacer'></div>
                <div className='Clicker' onClick={Close}></div>
                
            </div>

            <div className='menuDrop' ref={containerRef}>
            {(props.rColor=='home')?<Link to="/" onClick={handleButtonHome}><h2 style={{color:'#6C0BDB'}}>Home</h2></Link>:<Link to="/" onClick={handleButtonHome}><h2>Home</h2></Link>}
            {(props.rColor=='about')?<Link to="/" onClick={handleButtonClick}><h2 style={{color:'#6C0BDB'}}>About</h2></Link>:<Link to="/" onClick={handleButtonClick}><h2>About</h2></Link>}
                <Link to="/services"><h2>Services</h2></Link>
                <Link to="/testimonials"><h2>Testimonials</h2></Link>
                <Link to="/contactus"><h2>Contact Us</h2></Link>

                <a
                    href="https://www.behance.net/kesteradeyelu/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="behanceMobile"
                >
                    <img
                    alt="image"
                    src="/external/behance-200h.png"
                    className="home-image"
                    />
                </a>
            </div>
            
        </div>
    );
}

export default MenuDrop;