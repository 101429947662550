import React, { useEffect } from 'react'
import '../views/home.css';
import gsap from 'gsap';
import { Link } from 'react-router-dom'

const SiteName = ({timer}) =>{

    useEffect(()=>{
        gsap.fromTo('.home-text', {
            y:-70
        }, {y:0, delay:timer})
    },[])

    const handleButtonClick = () => {
        const HomeNav = 'Home';
        sessionStorage.setItem('setter', HomeNav);
        // props.myFunction();
      };
    return(
        <span className="home-text"><Link to="/#" className="about-navlink" onClick={handleButtonClick}>Kester Studios</Link></span>
    );
}

export default SiteName;