import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import '../views/home.css'
import './typewriter.css'


const TypewritingAnimation = ({ text, durationX }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;

    gsap.fromTo(
      textElement,
      { opacity: 0, width: 0 },
      { opacity: 1, width: 'auto', duration: 2, delay: durationX }
    );
  }, []);

  return <div className="typewriter"><div ref={textRef} className="typing-text">{text}</div></div>;
};

export default TypewritingAnimation;